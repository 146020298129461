/* @import "https://fonts.googleapis.com/css?family=Roboto:300i,400,500,700&display=swap"; */
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

html {
  scrollbar-color: #0e3786 #ddd;
  scrollbar-width: thin !important;
}

body {
  margin: 0;
  /* font-family: Roboto, sans-serif !important; */
  font-family: Poppins, sans-serif !important;
  color: #333;
  font-size: 15px;
}

#content {
  /* padding-top: 20px; */
  padding-bottom: 20px;
}

p {
  font-family: Roboto, sans-serif !important;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.7em;
  margin-bottom: 5px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

a {
  cursor: pointer;
}

a,
a:focus,
a:hover {
  color: inherit;
  text-decoration: none !important;
  transition: all 0.3s;
}

a:hover {
  text-decoration: none;
}

:focus {
  outline: -webkit-focus-ring-color auto 0 !important;
}

hr {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0 !important;
}

ul {
  padding-left: 0;
  margin: 0;
}

ul li {
  list-style-type: none;
}

ul ul a {
  font-size: 15px !important;
  padding-left: 0 !important;
  background: 0 0 !important;
}

ul ul link:hover {
  background: #fff !important;
}

tr {
  transition: 0.5s all !important;
}

.notify-responsive {
  display: none;
}

.navbar-toggler {
  border: 0 solid transparent !important;
}

.navbar {
  padding: 0px !important;
  /* background: #fff !important; */
}

.navbar-toggler-icon {
  background-image: url(../images/menu.png) !important;
}

.nav-link {
  text-align: center;
  color: #333;
  font-size: 13px;
  cursor: pointer;
  font-weight: 500;
  /*border-bottom: 5px solid transparent;*/
}

.nav-link-sub {
  padding: 0.5rem 7px !important;
}

.navbar-brand {
  margin-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0;
}

.navbar-pad-zero {
  padding: 0 10px !important;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 6px !important;
  height: 20px !important;
}

.navbar-nav .dropdown-menu {
  right: 0;
  left: auto !important;
}

.roundImg {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  padding: 3px;
  box-shadow: 1px 1px 8px 1px #b7b4b4;
  background-color: #fff;
}

.navbar-nav .dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 43% !important;
  right: 6px !important;
  transform: translateY(-50%);
}

.nav-border-none .nav-link {
  border-bottom: none;
  border-image-slice: unset;
  text-align: left !important;
}

.fade {
  -webkit-transition: opacity 0.1s ease-in !important;
  -moz-transition: opacity 0.1s ease-in !important;
  -o-transition: opacity 0.1s ease-in !important;
  -ms-transition: opacity 0.1s ease-in !important;
  transition: opacity 0.1s ease-in !important;
}

.btn {
  cursor: pointer !important;
  /* padding: .225rem .75rem !important; */
  user-select: inherit !important;
}

.font23 {
  font-size: 23px !important;
  margin: 3px;
}

.floating-dropdown .dropdown-menu {
  background-color: transparent !important;
  border: 0 solid #fff !important;
  min-width: 30px !important;
  text-align: center !important;
}

.floating-dropdown .dropdown-item {
  background-color: #fff !important;
  color: #0150a8;
  width: 45px !important;
  height: 45px !important;
  line-break: 15px;
  margin: 4px 0 !important;
  border-radius: 50px;
  padding: 13px 10px !important;
  box-shadow: 1px 1px 8px 1px #0000007d;
}

.floating-dropdown .dropdown-toggle::after {
  color: transparent !important;
}

.notification {
  position: relative;
  /* display: inline-block; */
  display: inline flow-root list-item !important;
}

.notification .badge {
  position: absolute;
  top: 2px;
  right: 0;
  padding: 3px 6px;
  border-radius: 50%;
  background-color: #db3737;
  color: #fff;
  line-height: 18px;
  font-size: 13px;
  min-width: 24px;
}

.notification-media {
  transition: all 0.3s;
  padding: 2px;
  box-shadow: 0 0 0 0 #3333334f !important;
  background-color: transparent;
  outline: none;
  border: none;
}

.notification-media:hover {
  background-color: #ececec;
}

.notification-media img {
  padding: 7px 4px 7px 0;
}

.notification-media .media-body {
  text-align: left;
}

.notification-media p {
  color: #000;
}

.breadcrumb {
  /*background: linear-gradient(-45deg, #024896, #014da0, #3f8be0, #0150a8) !important;*/
  border-radius: 0 !important;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 1rem !important;
  margin-bottom: 1rem;
  list-style: none;
  /*border-top: 4px solid #ccc;
  border-bottom: 4px solid #ccc;*/
}

.breadcrumb a {
  color: #0e3786;
  text-transform: capitalize;
}

.breadcrumb a:hover {
  color: #7c7c7d;
}

.breadcrumb-item.active {
  color: #fff !important;
}

.dashboard-card {
  background: linear-gradient(130deg, #3c91e7 0, #00bcd4 100%);
  color: #fff;
  box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.1) !important;
}

.dashboard-card-img img {
  line-height: 39px;
  text-align: center;
  border-radius: 50%;
  background-clip: padding-box;
  color: #3b87db;
  background-color: #fff;
  padding: 10px;
  box-shadow: 1px 1px 9px 1px #fff;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.Table__itemCount {
  font-size: 14px;
}

.Table__pagination .btn-link {
  color: #1d1d1d !important;
  font-weight: 600;
}

.ReactTable {
  margin-top: 5px;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.6) !important;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.6) !important;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 10px 5px !important;
  line-height: normal;
  position: relative;
  color: #000 !important;
  background: #dedede78;
  font-weight: 500;
  text-align: left;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
}

.ReactTable .rt-thead.-header {
  box-shadow: 0 2px 15px 0 #b1b1b1 !important;
}

.ReactTable .rt-thead .rt-th:before {
  content: "\29D7";
  float: right;
  color: #b7b7b7;
}

.ReactTable .rt-thead .rt-th:first-child:before {
  content: "";
  float: right;
}

.ReactTable .rt-thead .rt-th:last-child:before {
  content: "";
  float: right;
}

.ReactTable .-sort-desc {
  box-shadow: none !important;
}

.ReactTable .-sort-desc:before {
  content: "\25B4" !important;
  float: right;
}

.ReactTable .-sort-asc {
  box-shadow: none !important;
}

.go-right-80 {
  transform: translate3d(-80px, 30px, 0px) !important;
}

.ReactTable .-sort-asc:before {
  content: "\25BE" !important;
  float: right;
}

.invoiceAdd-Total .form-control {
  /* border-bottom: 0 solid #fff !important; */
  font-size: 19px !important;
  font-weight: 500 !important;
  /* text-align: right !important; */
  letter-spacing: 1px;
}

.__react_component_tooltip.type-light {
  color: #fff !important;
  background-color: #000 !important;
}

.__react_component_tooltip.type-light.place-bottom:after {
  border-bottom-color: #000 !important;
  border-bottom-style: solid;
  border-bottom-width: 6px;
}

.__react_component_tooltip {
  border-radius: 5px !important;
  padding: 3px 10px !important;
}

.btn-pad-zero {
  padding: 0 !important;
}

.btn-link-view {
  padding: 0 !important;
  border-bottom: 1px dotted #0063d2 !important;
}

.btn-null {
  background-color: transparent !important;
  border: 0 solid transparent !important;
}

.btn-success {
  color: #fff;
  background-color: #8bc34a !important;
  border-color: #78a93f !important;
}

.btn-success:hover {
  color: #fff;
  background-color: #598624 !important;
  border-color: #5d8a28 !important;
}

.btn-primary {
  color: #fff;
  background-color: #0063d2 !important;
  /*background: linear-gradient(-45deg, #024896, #014da0, #3f8be0, #0150a8) !important;*/
  border-color: #000 !important;
  font-size: 16px !important;
  padding: 7px 13px !important;
}

.btn-danger {
  color: #fff;
  background-color: #c82333;
  /*background: linear-gradient(-45deg, #dc3545, #bd1c11, #e25e55, #e8162a) !important;*/
  border-color: #ef1403 !important;
  font-size: 16px !important;
  padding: 7px 13px !important;
}

.btn-warning {
  color: #fff !important;
  background-color: #ff9800 !important;
  border-color: #ff9800 !important;
}

.btn {
  /*border-radius: 4px !important;*/
  border-radius: 5px !important;
  line-height: 14px !important;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 2px 5px !important;
  font-size: 13px !important;
  line-height: 13px !important;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(157, 161, 167, 0) !important;
}

button:focus {
  outline: 0 dotted !important;
  outline: 0 auto -webkit-focus-ring-color !important;
}

.btn-white {
  color: #1d1d1d !important;
  background-color: #fff !important;
  border-color: #fff !important;
  padding: 7px 10px !important;
}

.btn-white:hover {
  color: #fff !important;
  text-decoration: none;
  background-color: transparent !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff !important;
  text-decoration: none;
  background-color: #0063d2 !important;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.48), 0 4px 15px 0 rgba(0, 0, 0, 0.1);
}

.dropdown-item a {
  font-size: 16px;
  color: #000;
}

.dropdown-item a:hover {
  color: #fff !important;
}

.dropdown-item a:focus {
  color: #fff !important;
}

.border-right {
  border-right: 1px dotted #ccc;
}

.border-bottom {
  border-bottom: 1px dotted #ccc;
}

.zero-margin {
  margin: 0 !important;
}

.zero-pad {
  padding: 0 !important;
}

.pad15 {
  padding: 0 15px;
}

.pl20 {
  padding-left: 20px;
}

.font11 {
  font-size: 11px;
}

.font19 {
  font-size: 19px !important;
}

.font12 {
  font-size: 12px !important;
}

.ml15 {
  margin-left: 15px;
}

.mt10 {
  margin-top: 10px;
}

.mt7 {
  margin-top: 7px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mt20 {
  margin-top: 20px;
}

.mt3 {
  margin-top: 3px;
}

.mt30 {
  margin-top: 30px;
}

.mt50 {
  margin-top: 50px;
}

.mt250 {
  margin-top: 250px;
}

.ml5 {
  margin-left: 5px;
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr20 {
  margin-right: 20px;
}

.pad10 {
  padding: 10px !important;
}

.pb-0 {
  padding-bottom: 0px;
}

.pt-0 {
  padding-top: 0px;
}

.bold {
  font-weight: 600;
}

.text-green {
  color: #007d08;
}

.text-red {
  color: #dc3545 !important;
}

.text-blue {
  color: #0e3786 !important;
}

.text-gray {
  color: #696565;
}

.text-bold {
  font-weight: 900 !important;
}

.text-right {
  text-align: right;
}

.cardBG {
  height: 70px;
  background: url(../images/bg1.png) no-repeat;
  background-size: cover;
}

.list-group-item {
  padding: 10px 5px !important;
  text-transform: uppercase;
}

.card {
  border: 0 solid #fff !important;
  box-shadow: 0px 0px 10px -3px;
  border-radius: 0.75rem !important;
}

.card-body {
  flex: 1 1 auto;
  border: 0 solid #9e9e9e3b;
  padding: 15px !important;
}

.card-header {
  /* padding: 15px 10px !important; */
  margin-bottom: 0;
  border-bottom: none !important;
  background-color: #fff !important;
  padding-top: 0px !important;
  padding-bottom: 15px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.card-headline {
  color: #0e3786;
  font-weight: 600;
  text-transform: uppercase;
}

.add-icon {
  /*margin: 3px;*/
  font-size: 32px;
  color: #1d1d1d;
  border-radius: 50px;
}

/* .collapse {
  display: none;
} */
.collapse.in {
  display: block;
}

.col-lg-1 {
  -ms-flex: 0 0 12% !important;
  flex: 0 0 12% !important;
  max-width: 12% !important;
}

textarea.form-control {
  height: auto !important;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0 10px 0 0 !important;
  margin-bottom: 0;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #0753a6 !important;
  text-align: center;
  white-space: nowrap;
  background-color: transparent !important;
  border: 0 solid #ced4da !important;
  /* border-bottom: 1px solid #95c4f9 !important; */
  border-radius: 0 !important;
}

.form-padding {
  padding: 0 15px;
}

.error {
  font-size: 12px;
  color: red;
}

.alert {
  width: 100%;
  position: absolute !important;
  z-index: 2222 !important;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 10px !important;
  margin-top: 45%;
}

.alert-danger {
  color: #333 !important;
  border-color: #fff !important;
}

label {
  margin-bottom: 2px !important;
  font-size: 14px !important;
  color: #0e3786;
  font-weight: 500;
  padding-left: 8px;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #1d1d1d !important;
  /* border: 2px solid !important; */
  outline: 0;
  box-shadow: inset 0px 0px 10px -6px #1d1d1d !important;
  /* box-shadow: none !important; */
}

.form-control {
  height: 38px !important;
  padding: 2px 10px !important;
  font-size: 1rem !important;
  line-height: 32px !important;
  border-radius: 10px !important;
  border: 1px solid #b0b0b0 !important;
  /* margin-bottom: 10px; */
  /* border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important; */
}

.css-1xzjvfp-control {
  border: 1px solid #b0b0b0 !important;
  border-radius: 10px !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef73 !important;
  opacity: 1;
  cursor: not-allowed;
}

.chk-form-control .form-control {
  height: 18px !important;
}

.form-group {
  margin-bottom: 8px !important;
}

.activelink {
  color: #fff !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background-color: #29b6f6;
  background-image: linear-gradient(
    -55deg,
    #09abf4,
    #0dcff8,
    #0dcef7,
    #04aaf4
  ) !important;
  font-weight: 500;
  border-bottom: 0 solid #ccc !important;
}

/* select:focus {
  box-shadow: 0 0 0 rgba(255, 255, 255, 0) inset, 0 0 0 rgba(126, 239, 104, 0.6) !important;
  outline: 0 none !important;
  background-color: transparent !important;
} */
.css-1okebmr-indicatorSeparator {
  background-color: hsla(0, 0%, 80%, 0) !important;
}

.css-tlfecz-indicatorContainer {
  color: hsl(211, 82%, 38%) !important;
}

#SalesChartID {
  width: 100%;
}

.PieChart {
  margin: 20px;
}

#piechart {
  width: 100%;
  height: 360px;
  overflow: hidden;
}

.dropdown-item {
  padding: 1px 8px;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #1d1d1d !important;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 2px !important;
  transform: translateY(-50%);
}

#_ABSTRACT_RENDERER_ID_0 rect {
  width: 80%;
}

.custom-control-label::before {
  border: #024da0 solid 2px !important;
}

.creadit-card-form {
  padding: 30px 25px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 8px 0 #2b333cb0;
}

.creadit-card-form .form-control {
  background-color: transparent !important;
  border: 1px solid #b2b3b3 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.custome-title {
  font-weight: 500;
  color: #0063d2;
}

.spinner {
  animation: spin 0.75s infinite;
  transform: rotate(0deg);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.creadit-card-form .text-dark {
  color: #000 !important;
  text-shadow: 0 0 6px #afb6bd, 0 3px 1px #a8a8ad;
  font-size: 17px;
}

.inputtabs-btn li {
  list-style-type: none;
  display: inline-block;
  margin: 3px;
  /* background-color: #fff; */
  /*border-radius: 5px;*/
  /* border: 2px solid #7c7c7d; */
  /* border-radius: 20px; */
  /*box-shadow: 1px 1px 5px 1px #c3c3c3;*/
}

.inputtabs-btn li button {
  background-color: #1d1d1d;
  color: #fff !important;
  border-radius: 25px !important;
  border: 2px solid #1d1d1d;
}

.inputtabs-btn li button:hover {
  background-color: #fff;
  border: 2px solid #1d1d1d;
  color: #1d1d1d !important;
  border-radius: 25px !important;
}

.modalBorder {
  border-radius: 6px;
}

.zIndex-0 {
  z-index: 0 !important;
}

.customeModal {
  max-width: 1000px;
  width: 1000px;
  border-radius: 6px;
  margin-top: 3%;
}

.ModalWidth {
  max-width: 1000px !important;
  /* border-radius: 6px;
  width: 2000px; */
}

/* .ModalWidth600 {
  max-width: 600px !important;
  border-radius: 6px;
  width: 600px;
}

.ModalWidth-Small {
  max-width: 400px;
  border-radius: 6px;
  width: 400px;
  margin: 10% auto;
}

.ModalWidth800 {
  max-width: 800px !important;
  border-radius: 6px;
  width: 800px;
} */

.none {
  display: none;
}

.modal-header {
  margin-bottom: 10px;
  padding: 10px 10px !important;
  /*background: linear-gradient(-45deg, #0066d8, #024a98, #84b7ef52, #fff) !important;*/
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  /* border-radius: 0px !important; */
  /* border-radius: calc(0.75rem - 1px) calc(0.75rem - 1px) 0 0 !important; */
  border-radius: 5px !important;
  /*box-shadow: inset 0 0 7px #0063d2;*/
  color: #fff;
  background-color: #0e3786;
}

.modal-header .close {
  padding: 5px 8px 7px !important;
  margin: 0 !important;
  background-color: #dc3545;
  opacity: 1 !important;
  line-height: 25px;
  vertical-align: middle;
  text-align: center;
  border-radius: 50px;
  color: #fff;
  border: 0 solid #fff;
  font-size: 17px;
  font-weight: 500;
}

.modal-header .close:hover {
  background-color: #000;
  border: 0 solid #fff;
  color: #fff !important;
  opacity: 1 !important;
}

.modal-content {
  padding: 0.5rem !important;
}

.less-gutter {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.modal-header p {
  margin-bottom: 0 !important;
}

.modal-title {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px !important;
  text-transform: uppercase;
}

.modal-footer {
  padding: 0 !important;
  border-top: 0 solid #dee2e6 !important;
}

.modal-footer .btn {
  margin: 5px;
}

.zindex {
  z-index: 111111;
}

.modalDelete p {
  font-size: 20px;
  text-align: center;
  padding: 20px;
}

.modalDelete .close {
  padding: 5px 8px 7px !important;
  margin: -12px !important;
  background-color: #dc3545;
  opacity: 1 !important;
  line-height: 25px;
  vertical-align: middle;
  text-align: center;
  border-radius: 50px;
  color: #fff;
  border: 0 solid #fff;
  font-size: 17px;
  font-weight: 500;
}

.modalDelete .close:hover {
  background-color: #000;
  color: #fff;
}

.table-modal table tbody tr td label {
  font-size: 14px !important;
}

.table-modal table tbody tr td {
  padding: 3px 10px !important;
}

.table-modal table tbody tr td span {
  font-size: 14px;
}

.part-intro-box {
  padding: 30px 10px 20px;
  background-color: #fff;
  margin: 30px 0 15px;
  /*border-radius: 10px;*/
  box-shadow: 1px 2px 6px 1px #a9a9a9;
  border-radius: 15px;
}

.parts-name {
  /*background: linear-gradient(-45deg, #024896, #014da0, #3f8be0, #0150a8) !important;*/
  background: #1d1d1d;
  color: #fff;
  border: 1px solid #000;
  width: 40%;
  left: 30%;
  justify-content: center;
  top: 5px;
  text-align: center;
  position: absolute;
  display: inline-block;
  /*border-radius: 4px;*/
  border-radius: 10px;
  font-size: 13px;
  padding: 5px;
  font-weight: 500;
  box-shadow: 1px 5px 8px 1px #9a9696;
  margin: 15px auto;
}

.parts-stock {
  font-size: 14px;
  padding: 0;
  font-weight: 500;
}

.react-dropdown-select {
  height: 32px !important;
  min-height: 32px !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 1px solid #a4c9f3 !important;
}

.react-dropdown-select:focus {
  box-shadow: 0 0 0 0 rgba(0, 116, 217, 0.2) !important;
}

.react-dropdown-select-dropdown {
  width: 100% !important;
  top: 40px !important;
}

.preImg img {
  width: 100px;
  margin: 10px;
}

.preImg {
  margin-bottom: 0;
}

.preImg li {
  list-style-type: none;
  display: inline-block;
}

.ReactModal__Content {
  width: 100% !important;
}

.filepicker-file-icon::after {
  background-color: #014da0 !important;
}

.SignatureImg img {
  width: 160px;
  border: 1px solid #ccc;
  margin-top: 6px;
  float: right;
  box-shadow: 1px 2px 10px 1px #ccc;
  border-radius: 6px;
}

.user-signature {
  width: 110px;
  float: right;
}

.customer-signature {
  width: 140px;
}

.swiper-wrapper {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.swiper-wrapper .card-body {
  padding: 7px !important;
}

.swiper-slide {
  margin-bottom: 10px;
}

.swiper-slide i {
  color: #3f8be0;
  -webkit-transition: -webkit-transform 1s ease-in-out;
  transition: transform 1s ease-in-out;
}

.swiper-slide i:hover {
  color: #000;
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.icon-cards-row .card {
  -webkit-transition: -webkit-box-shadow 1s;
  transition: -webkit-box-shadow 1s;
  transition: box-shadow 1s;
  transition: box-shadow 1s, -webkit-box-shadow 1s;
  cursor: pointer;
}

.swiper-slide .card-body:hover {
  /* background-color: #e6e6e6; */
  border: #ababab;
  box-shadow: 0px 0px 11px -5px #7f7f7f !important;
  background: #fff;
  transform: scale(1.05);
  transition: 0.5s;
  border-radius: 0.25rem;
}

.swiper-marketing .card-body {
  padding: 3px !important;
  border: 1px solid #9e9e9e !important;
}

.lead {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.popover {
  min-width: 300px !important;
}

.searchPopover .popover {
  min-width: fit-content !important;
}
.searchPopover .popover .popover-inner {
  position: relative;
}

.popover-footer {
  padding: 10px 4px;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
  box-shadow: 0 2px 10px 0 #33333394;
  border: 0 solid #fff !important;
  border-radius: 5px;
}

.card-featured-primary {
  border-color: #08c;
  border-color: #ccc;
}

.widget-summary {
  display: table;
  width: 100%;
}

.dashboard-box:hover {
  /*border-radius: 15px;
  background: linear-gradient(-112deg, #d8e8fac4, #d8e8f9, #fff, #fff) !important;*/
  -moz-box-shadow: 0 0 10px #ababab;
  -webkit-box-shadow: 0 0 10px #ababab;
  box-shadow: 0 0 10px #ababab;
  transform: scale(1.05);
  transition: 0.5s;
}

.widget-summary .widget-summary-col.widget-summary-col-icon {
  /*width: 1%;*/
  position: relative;
}

.widget-summary .widget-summary-col {
  /*display: table-cell;*/
  vertical-align: top;
  width: 100%;
  /*display: table-cell;*/
  vertical-align: top;
  width: 100%;
}

.widget-summary .summary {
  min-height: 20px;
  word-break: break-all;
  text-align: right;
}

.widget-summary .summary .title {
  margin: 0;
  font-size: 12px;
  text-align: left;
  color: #0e3786;
  font-weight: 600;
}

.account-more-info {
  background-color: #0e3786;
  border-radius: 0rem 0px 0.75rem 0.75rem;
  /* box-shadow: -5px 5px 5px 1px #0e378659; */
  font-size: 11px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  padding: 2px;
}

.widget-summary .summary .info {
  font-size: 13.6px;
  font-size: 0.85rem;
}

.widget-summary .summary-footer {
  padding: 25px 0px 15px 6px;
  /* border-top: 1px dotted #ddd; */
  text-align: left;
  color: #000;
  font-size: 14px;
  /* display: table-cell; */
  vertical-align: middle;
}

.widget-summary .summary-footer p {
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.widget-summary::after {
  clear: both;
  content: "";
  display: block;
}

.account-more-info:hover + .main {
  display: none;
}

.account-more-info:hover + .second {
  display: block !important;
}

.avatar-upload {
  position: relative;
  max-width: 160px;
  margin: 9px auto;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 25px;
  z-index: 2;
  top: 6px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

/* .avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #2370c6;
  border: 1px solid transparent;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
} */
/* .avatar-upload .avatar-edit input + label:hover {
  background: #000;
  border-color: #333;
} */
/* .avatar-upload .avatar-edit input + label:after {
  content: "\f303";
  font-family: FontAwesome;
  color: #fff;
  position: absolute;
  top: 8px;
  left: -1px;
  right: 0;
  text-align: center;
  margin: auto;
} */
.avatar-upload .avatar-preview {
  width: 160px;
  height: 160px;
  position: relative;
  border-radius: 50%;
  /* border: 1px solid #ccc; */
  box-shadow: 0 0px 2px 2px #0e3786;
}

.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#imagePreview {
  border-radius: 50%;
  height: 150px !important;
  width: 150px !important;
  z-index: 1;
  left: 5px;
  top: 5px;
}

.no-radius {
  border-radius: 0 !important;
}

.no-radius .avatar-preview {
  border-radius: 0 !important;
  box-shadow: 1px 2px 10px 1px #ccc;
}

.fixedHeight {
  max-height: 130px;
  overflow-y: auto;
}

.fixedHeight div {
  border: 1px solid #ccc;
  padding: 0px 6px;
  border-radius: 6px;
  /* margin: 5px; */
}

.ssn-proof {
  border: 1px solid #ccc;
  padding: 3px 6px;
  border-radius: 6px;
  margin: 5px;
  overflow: auto;
  word-break: break-word;
}

.custom-file-upload input[type="file"] {
  display: none;
}

.doc-file-preview {
  margin: 0 !important;
  border: 1px solid #ccc !important;
  margin-bottom: 8px !important;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  color: #0063d2;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  background-color: #c5c5c582;
  margin-bottom: 10px !important;
}

.invoice-bottom-info {
  margin: 30px 5px !important;
  padding: 20px 0;
  box-shadow: 1px 1px 10px 1px #b3b3b3;
  border-radius: 15px;
}

.invoice {
  background-color: #fff;
  padding: 0 15px;
}

.invoice .invoice-top {
  padding: 20px 0;
  margin-bottom: 15px;
  border-bottom: 1px dotted #0063d2;
}

.invoice .company-details {
  text-align: right;
}

.invoice .company-details .name {
  margin-top: 0;
  margin-bottom: 0;
}

.invoice .invoice-contacts p {
  font-size: 16px;
}

.invoice .invoice-to {
  text-align: left;
}

.invoice .invoice-to .to {
  margin-top: 0;
  margin-bottom: 0;
}

.invoice .invoice-details {
  text-align: right;
}

.invoice .invoice-details .invoice-type {
  margin-top: 0;
  color: #0063d2;
}

.invoice .invoice-details table {
  margin-bottom: 0 !important;
}

.invoice .notices {
  padding-left: 15px;
  border-left: 6px solid #0063d2;
}

.invoice .notices .notice {
  font-size: 16px;
}

.invoice .bg-none {
  background-color: transparent !important;
}

.invoice .bg-blue {
  background-color: #0063d2;
  color: #fff;
}

.invoice .address {
  font-size: 15px;
}

.invoice footer {
  width: 100%;
  text-align: center;
  color: #777;
  border-top: 1px solid #aaa;
  padding: 8px 0;
}

@media print {
  .invoice {
    font-size: 11px !important;
    overflow: hidden !important;
  }

  .invoice footer {
    position: absolute;
    bottom: 10px;
    page-break-after: always;
  }

  .invoice > div:last-child {
    page-break-before: always;
  }
}

.terminal-form {
  padding: 15px;
  box-shadow: 1px 2px 10px 1px #afafaf;
  margin: 20px 10px 5px;
  border-radius: 6px;
}

.customer-and-machine-info {
  padding: 5px 15px;
}

.error-info {
  padding: 15px;
  box-shadow: 1px 2px 10px 1px #afafaf;
  margin: 20px 10px 5px;
  border-radius: 6px;
}

.note-section {
  font-size: 18px;
  background: #ffe50036;
  padding: 10px;
  border-radius: 6px;
  color: #757575;
}

.email2Icon img {
  margin: 10px 40px;
}

.campaign-form {
  background-color: #c2dbff59;
  margin: 3px !important;
  border-radius: 10px;
  border: 0 dotted #014a9a;
  -moz-box-shadow: inset 0 0 10px #9e9e9e;
  -webkit-box-shadow: inset 0 0 10px #9e9e9e;
  box-shadow: inset 0 0 10px #848484;
  padding: 22px;
}

.campaign-form .form-control {
  background-color: transparent !important;
}

.CareerEmp img {
  width: 75px;
  height: 75px;
  margin-right: 20px;
  border-radius: 50px;
  box-shadow: 1px 2px 10px 1px #bababb;
}

.media {
  display: flex;
  align-items: flex-start;
  box-shadow: 1px 2px 10px 1px #3333334f;
  margin-bottom: 5px;
  border-radius: 10px;
}

.media-body {
  padding-top: 7px;
}

.timeline-scroll {
  max-height: 470px;
  overflow-y: auto;
}

#timeline {
  width: 100%;
  margin: 60px auto;
  position: relative;
  padding: 0 10px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

#timeline:before {
  content: "";
  width: 3px;
  height: 100%;
  background: #a2a2a2;
  left: 2%;
  top: 0;
  position: absolute;
}

#timeline:after {
  content: "";
  clear: both;
  display: table;
  width: 100%;
}

#timeline .timeline-item {
  margin-bottom: 60px;
  position: relative;
}

#timeline .timeline-item .timeline-date {
  background: #1b67ba;
  width: auto;
  height: auto;
  position: absolute;
  top: -36px;
  left: 0;
  overflow: hidden;
  margin-left: 0;
  padding: 5px 8px;
  border-radius: 3px;
  color: #fff;
}

#timeline .timeline-item .timeline-content {
  width: 100%;
  background: #fff;
  padding: 20px;
  -webkit-box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.37);
  -ms-box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.37);
  box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.37);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#timeline .timeline-item .timeline-content h6 {
  padding: 5px;
  background: #1b67ba;
  color: #fff;
  margin: -20px -20px 0;
  font-weight: 300;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  -ms-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}

.timeline-content p {
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .navbar-pad-zero {
    padding: 10px !important;
  }

  #timeline {
    margin: 30px;
    padding: 0;
    width: 90%;
  }

  #timeline:before {
    left: 0;
  }

  #timeline .timeline-item .timeline-content {
    width: 90%;
    float: right;
  }

  #timeline .timeline-item .timeline-content:before,
  #timeline .timeline-item .timeline-content.right:before {
    left: 10%;
    margin-left: -6px;
    border-left: 0;
    border-right: 7px solid #1b67ba;
  }

  #timeline .timeline-item .timeline-date {
    left: 0;
  }

  .flip-card {
    height: 150px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1360px) {
  .navbar-pad-zero2 {
    padding: 0 10px !important;
  }

  .nav-link {
    padding: 0.5rem 5px !important;
  }

  .flip-card {
    height: 135px !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 991px) {
  .navbar-pad-zero {
    padding: 10px 5px 10px 10px !important;
  }
}

input {
  outline: none;
}

/* .closesearchbar {
  padding-left: 0px !important;
  position: absolute;
  top: 8px;
}

.closesearchbar .fa {
  position: absolute;
  right: 30px;
  bottom: -7px;
} */

.closesearchbar {
  position: absolute;
  right: 22px;
  top: -4px;
}

.closesearchbar i {
  background: #fff !important;
  padding: 6px 9px 7px 7px;
}

@media (max-width: 480px) {
  .closesearchbar {
    top: 10px;
  }
}

input[type="search"] {
  box-sizing: border-box !important;
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  font-family: inherit;
  font-size: 100%;
  color: #1d1d1d;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
  display: none;
}

input[type="search"] {
  /* background: transparent url(../images/magnifier.png) no-repeat 12px center; */
  border: solid 1px #ccc;
  padding: 7px 10px 7px 32px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  width: 380px;
  /* width: fit-content; */
  max-width: -webkit-fill-available;
  max-width: -moz-available;
  /* margin-right: 10px; */
  /* background-color: #00000045; */
  /* -webkit-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
  -moz-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
  box-shadow: 0 0 5px rgba(109, 207, 246, 0.5); */
  padding: 4px 0 4px 8px;
}

/* input[type="search"]:focus {
  width: 130px;
  background-color: #00000045;
  -webkit-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
  -moz-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
  box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
  padding: 7px 0 7px 32px;
  margin-right: 0;
}
#demo-2 input[type="search"] {
  width: 20px;
  height: 17px;
  padding-left: 10px;
  color: transparent;
  cursor: pointer;
}
#demo-2 input[type="search"]:hover {
  background-color: transparent;
}
#demo-2 input[type="search"]:focus {
  width: 130px;
  padding-left: 32px;
  color: #fff;
  background-color: #00000045;
  cursor: auto;
  margin-right: 10px;
}
#demo-2 input:-moz-placeholder {
  color: transparent;
}
#demo-2 input::-webkit-input-placeholder {
  color: transparent;
} */
.profile-card {
  position: relative;
  float: left;
  overflow: hidden;
  width: 100%;
  text-align: center;
  height: auto;
  border: none;
  box-shadow: 1px 2px 10px 1px #3333338c;
  border-radius: 17px !important;
  margin-bottom: 30px;
}

.profile-card .background-block {
  background: linear-gradient(
    -45deg,
    #024896,
    #014da0,
    #3f8be0,
    #0150a8
  ) !important;
  float: left;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.profile-card .background-block .background {
  width: 100%;
  vertical-align: top;
  opacity: 0.9;
  -webkit-filter: blur(0.5px);
  filter: blur(0.5px);
  -webkit-transform: scale(1.8);
  transform: scale(1.8);
}

.profile-card .card-content {
  width: 100%;
  padding: 15px 25px;
  color: #232323;
  float: left;
  background: #efefef;
  height: 50%;
  border-radius: 0 0 5px 5px;
  position: relative;
  z-index: 9999;
}

.profile-card .card-content::before {
  content: "";
  background: #efefef;
  width: 120%;
  height: 100%;
  left: 11px;
  bottom: 60px;
  position: absolute;
  z-index: -1;
  transform: rotate(-13deg);
}

.profile-card .profile {
  border-radius: 50%;
  position: absolute;
  bottom: 53%;
  left: 50%;
  max-width: 100px;
  opacity: 1;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.5);
  border: 2px solid rgba(255, 255, 255, 1);
  -webkit-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  z-index: 99999;
}

.profile-card h2 {
  margin: 0 0 5px;
  font-weight: 600;
  font-size: 21px;
}

.profile-card h2 small {
  display: block;
  font-size: 15px;
  margin-top: 10px;
}

.profile-card i {
  display: inline-block;
  font-size: 16px;
  color: #232323;
  text-align: center;
  border: 1px solid #232323;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  margin: 0 5px;
}

.profile-card .icon-block {
  float: left;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
}

.profile-card .icon-block a {
  text-decoration: none;
}

.profile-card i:hover {
  background-color: #232323;
  color: #fff;
  text-decoration: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #ececec !important;
}

.profile-tabs {
  box-shadow: inset 0 0 10px #ababab;
  border-radius: 10px;
}

.profile-tabs .nav-item i {
  font-size: 22px;
}

.profile-tabs .nav-item a {
  font-size: 17px;
}

/* .profile-tabs .nav-item.show .nav-link,
.profile-tabs .nav-link.active {
  background: linear-gradient(-45deg, #024896, #014da0, #3f8be0, #0150a8) !important;
  border-radius: 10px;
  color: #fff !important;
  border-color: #014c9f !important;
  box-shadow: 1px 1px 6px 1px #014c9f;
}
.profile-tabs .nav-link:focus,
.profile-tabs .nav-link:hover {
  border-color: #014c9f;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background: linear-gradient(-45deg, #0060cc, #014da0, #3f8be0, #0069de) !important;
  box-shadow: 1px 1px 6px 1px #33333352;
  font-weight: 500;
} */
.nav-pills {
  padding: 20px 0;
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 10px #ababab;
}

.nav-pills li {
  margin: 0 10px;
}

.nav-pills .nav-link {
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

/* .tab-pane {
  box-shadow: inset 0 0 10px #ababab;
  padding: 20px;
  border-radius: 10px;
  background: #fff;
}

.tab-pane .form-control {
  background-color: transparent;
} */

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #333;
  z-index: 111;
  color: #fff;
}

::-webkit-input-placeholder {
  color: #6d6e71 !important;
  font-size: 14px !important;
}

::-moz-placeholder {
  color: #6d6e71 !important;
  font-size: 14px !important;
}

:-ms-input-placeholder {
  color: #6d6e71 !important;
  font-size: 14px !important;
}

:placeholder {
  color: #ccc !important;
  font-size: 14px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: inherit;
  margin: 0;
  opacity: 1;
}

input[type="text"]::-webkit-input-placeholder {
  color: #ccc !important;
  font-size: 15px !important;
}

input[type="number"]::-webkit-input-placeholder {
  color: #ccc !important;
  font-size: 14px !important;
}

input[type="password"]::-webkit-input-placeholder {
  color: #ccc !important;
  font-size: 14px !important;
}

input[type="email"]::-webkit-input-placeholder {
  color: #ccc !important;
  font-size: 14px !important;
}

textarea::-webkit-input-placeholder {
  color: #ccc !important;
  font-size: 14px !important;
}

::-webkit-scrollbar {
  width: 3px !important;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 30px;
}

::-webkit-scrollbar-thumb {
  background: #08419c;
  border-radius: 30px;
}

::-webkit-scrollbar-thumb:hover {
  background: #08419c;
}

::-moz-selection {
  background: #2976cc;
  color: #fff;
}

::selection {
  background: #2976cc;
  color: #fff;
}

svg > g > g:last-child {
  pointer-events: none;
}

.pad-0 {
  padding: 0 !important;
}

.ReactTable .-pagination .-btn {
  background-color: #0e3786 !important;
  color: #fff !important;
}

.footer-copyright {
  background-color: #ccc;
  z-index: 1;
}

.highcharts-credits {
  display: none;
}

.canvasjs-chart-credit {
  display: none;
}

.canvasjs-chart-canvas {
  position: relative !important;
}

.word-break {
  word-break: break-all;
}

.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}
.z-index-3 {
  z-index: 3;
}
.z-index-4 {
  z-index: 4;
}
.z-index-5 {
  z-index: 5;
}

@media (max-width: 768px) {
  .highcharts-background {
    max-width: 700;
  }
}

.ReactTable .-pagination .-center {
  flex: 1.5 1;
  text-align: center;
  margin-bottom: 0;
  display: inline-block !important;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.paginationBtn {
  border: none !important;
  border-radius: 5px !important;
  background-color: #ffffff;
  /* color: #fff !important; */
  /* padding: 8px 10px !important; */
  padding: 0px !important;
  margin: 3px;
  font-size: 15px !important;
  line-height: 10px !important;
  width: 30px;
  height: 30px;
}

.paginationBtn:hover {
  background-color: #000 !important;
  box-shadow: 0 10px 10px -2px rgba(0, 0, 0, 0.25);
}

.paginationBtn.active {
  background-color: #ffffff !important;
  color: #0e3786 !important;
  box-shadow: 0 10px 10px -2px rgba(0, 0, 0, 0.25);
  transition: background-color 0.3s;
  width: 30px;
  height: 30px;
}

/* .Table__pagination select {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
} */

.Table__pagination .btn-link,
.Table__pagination .btn-link:hover,
.Table__pagination .btn-link:active,
.Table__pagination .btn-link:focus {
  text-decoration: none !important;
}

thead > tr > th {
  /* background: white; */
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 0;
  z-index: 0;
  background-color: #0e3786;
  color: #fff;
  white-space: nowrap;
}

.fixedBottomTh > tr > td {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  color: #000;
  z-index: 1;
  box-shadow: 0px 0px 15px -5px;
}

.table {
  margin-bottom: 0px !important;
  /* border: 2px solid #2863d2; */
}

.table thead th {
  /* color: #fff !important; */
  font-size: 14px !important;
  border-top: none !important;
  border-bottom: none !important;
  letter-spacing: 1px;
}

.table thead th:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.table-bordered thead th:last-child {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.table-bordered thead th:first-child {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.table thead th:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.scrollTable {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: auto;
  scrollbar-width: thin;
}

.toast {
  position: fixed;
  z-index: 9999;
  left: 0px;
  right: 0px;
  margin: 0 auto;
}

.popover {
  border: none !important;
  background: none !important;
}

.popover-body {
  background-color: #fff !important;
}

.navicons {
  font-size: 35px;
}

.navbar-nav {
  margin-left: 30px;
}

.outsider {
  background: transparent;
}

.outsider:hover {
  box-shadow: none !important;
  background: none !important;
  color: none !important;
}

.card-icon {
  background-color: #0e3786;
  color: #fff;
  padding: 10px;
  font-size: 30px;
  width: 48px;
  height: 48px;
  text-align: center;
  position: absolute;
  top: -22px;
  left: 44%;
  border-radius: 10px;
}

/*.card-icon:hover{*/
/* Toggle our animation play state to running when we are hovering over our sticker */
/* animation-play-state: running;
}

@keyframes spin {
  100% {transform: rotate(1turn); }
}*/

.summary .blue {
  background-color: #0e3786;
  /*padding: 3px 15px;
  margin: 0px 1px;*/
  color: #fff;
}

.summary .green {
  background-color: green;
  /*padding: 3px 15px;
  margin: 0px 1px;*/
  color: #fff;
}

.summary span {
  font-weight: 500;
}

.summary .gray {
  background-color: #7c7c7d;
  /*padding: 3px 15px;
  margin: 0px 1px;*/
  color: #fff;
}

.summary .red {
  background-color: #ce3737;
  color: #fff;
}

.notification-icon {
  padding: 10px;
  font-size: 30px;
  color: #0e3786;
  box-shadow: 1px 1px 8px 1px #b7b4b4;
  background-color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.cardicon-back {
  width: 85px;
  float: left;
}

.thead-icon {
  padding: 6px 10px;
  margin-right: 10px;
  background-color: #0e3786;
  color: #fff;
  border-radius: 5px;
}

.search .thead-icon {
  border-radius: 0 5px 5px 0 !important;
  padding: 5px 10px !important;
  position: absolute;
  right: 0;
  margin-right: 0 !important;
}

.status-active {
  background: #28a7452e;
  padding: 2px 12px;
}

.status-inactive {
  background: #dc385f33;
  padding: 2px 12px;
}

.txt-blue {
  color: #007bff !important;
  background: #2863d22e !important;
  padding: 2px 12px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  border-radius: 10px !important;
}

.txt-red {
  color: #dc3545;
  background: #dc385f33;
  padding: 2px 12px;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 10px;
}

.txt-green {
  color: #28a745 !important;
  background: #28a7452e !important;
  padding: 2px 12px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  border-radius: 10px !important;
}

.txt-gray {
  color: #000;
  background: #7c7c7d47;
  padding: 2px 12px;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 10px;
}

tbody tr td {
  vertical-align: middle !important;
}

tbody tr:hover {
  box-shadow: 1px 0px 20px 0px #8b9bab;
  background-color: #fff !important;
  font-weight: 500;
  transition: 0.1s;
  /*height: 40px;*/
}

.single-icon {
  padding: 2px 12px;
}

.logo-margin {
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.top-menu {
  width: 85px;
}

.userinfo-left {
  /* box-shadow: 0px 0px 50px -25px; */
  padding: 25px;
  background-color: #fff;
}

.userinfo-right {
  /* box-shadow: 0px 0px 50px -25px; */
  box-shadow: 0px 0px 10px -5px;
  padding: 25px;
}

.userinfo-status {
  text-align: center;
}

.userinfo-status .bg {
  background: #f9f9f9;
  padding: 2px 12px;
  border-radius: 15px;
}

.userprofile-active {
  border: 5px solid #00803f;
  /* padding: 2px; */
}

.userprofile-inactive {
  border: 5px solid #db3737;
  /* padding: 2px; */
}

.userinfo-nametype {
  text-align: center;
  padding-top: 20px;
}

.userinfo-nametype .name {
  text-transform: uppercase;
  color: #0e3786;
  font-weight: 900;
}

.userinfo-nametype .type {
  color: #333;
  text-transform: lowercase;
}

.userinfo-box {
  /* background: #f9f9f9; */
  text-align: left;
  padding: 2px 4px;
  border-bottom: 1px dotted;
  font-size: 1vw;
}

@media screen and (max-width: 600px) {
  .userinfo-box,
  .userinfo-box button {
    font-size: 2.8vw;
  }

  .userinfo-right h4 {
    font-size: 4.2vw;
    font-weight: 900;
  }

  .userinfo-right h5 {
    font-size: 4vw;
  }
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .userinfo-box,
  .userinfo-box button {
    font-size: 2.8vw;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .userinfo-box,
  .userinfo-box button {
    font-size: 2.8vw;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .userinfo-box,
  .userinfo-box button {
    font-size: 2vw;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
  .userinfo-box,
  .userinfo-box button {
    font-size: 1vw;
  }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  .userinfo-box,
  .userinfo-box button {
    font-size: 1vw;
  }
}

.genicon {
  padding: 0px 10px;
  color: #0e3786;
}

.ver {
  color: #00803f;
  padding: 0px 10px;
}

.notver {
  color: #db3737;
  padding: 0px 10px;
}

.userinfo-whitebox {
  background: #fff;
  text-align: center;
  padding: 2px 4px;
}

.sign {
  width: 25%;
  border-radius: 3px;
  border: 1px solid #ababab;
}

.userinfo-whitebox-l {
  background: #fff;
  padding: 2px 4px;
}

.userinfo-box-l {
  background: #f9f9f9;
  padding: 2px 4px;
}

.userinfo-hire {
  border-top: 2px solid #f9f9f9;
}

.text-gray {
  font-size: 12px;
}

.modal {
  background-color: #2863d233;
}

.modal-content {
  border-radius: 0px !important;
}

/* .btn{
  border-radius: 20px !important;
} */

.noticon {
  font-size: 25px;
  padding: 15px;
}

.detaillabel {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.detaillabel span {
  background: #fff;
  padding: 0 10px;
  color: #1d1d1d;
}

.detailhead {
  padding: 6px 12px;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
}

.detailtitle {
  color: #7f7f7f;
  font-weight: 600;
}

.passkey {
  margin: 5px;
  /* border: 1px solid #7f7f7f; */
  text-align: center;
  padding: 10px;
  border: dashed #7f7f7f;
  font-weight: 900;
  letter-spacing: 3px;
  font-size: xx-large;
}

.passkey:hover {
  border: dashed #0e3786;
  color: #0e3786;
}

.bghighlight {
  padding: 20px;
  background: #2863d21c;
  border-radius: 10px;
}

.custdetail {
  padding: 20px;
  box-shadow: inset 0px 0px 11px -5px;
  border-radius: 10px;
}

.nobg {
  background-color: transparent !important;
}

.usercard {
  background-color: #f7f7f7 !important;
  height: 100%;
}

.usercard h5 {
  background-color: #0e3786;
  color: #fff;
  padding: 2px 10px;
}

.usercard p {
  border-bottom: 1px solid #0e3786;
}

.authuser {
  border: outset 2px #0e3786 !important;
  border-radius: 20px !important;
}

.authuser:hover {
  border: outset 2px #7f7f7f !important;
}

.addbutton {
  background: #fff;
  color: #0e3786;
  border: 0px;
  border-radius: 50%;
}

.addbutton:hover {
  transform: scale(1.2);
  transition: 0.3s;
}

.closebutton {
  background: #fff !important;
  color: #dc3545 !important;
  border: 0px !important;
  border-radius: 50% !important;
}

.closebutton:hover {
  transform: scale(1.2);
  transition: 0.3s;
}

.partcard {
  border: #ababab;
  box-shadow: 0px 12px -5px #7f7f7f;
}

.table a {
  font-weight: bold;
}

.addlicense {
  padding: 20px 10px;
  border-bottom: 2px solid #0e3786;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 2px solid #0e3786;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 20px;
}

.addlicense .cinfo {
  margin-bottom: 15px;
  padding: 20px 10px;
}

.addlicense ul li {
  background-color: transparent;
}

.addlicense .detailtitle {
  color: #0e3786;
}

.invoicemain {
  box-shadow: 0px 5px 15px -5px;
}

.invoice-contacts {
  /* padding: 20px 0px; */
  /* background-color: #2863d2; */
  /* color: #fff; */
  color: #0e3786;
}

.seller .name {
  border-bottom: 1px dotted rgb(255, 255, 255);
  text-transform: uppercase;
}

.seller .icon {
  background: #fff;
  color: #0e3786;
  font-size: 18px;
  border-radius: 50%;
}

.buyer .company {
  border-bottom: 1px dotted rgb(255, 255, 255);
  text-transform: uppercase;
}

.buyer .icon {
  /* background: #fff; */
  /* color: #fff; */
  font-size: 18px;
  border-radius: 50%;
}

.invoice-details .row {
  border-bottom: 1px dotted #fff;
}

.invoicetable tr:hover {
  box-shadow: none;
  transform: none;
  font-weight: inherit;
}

.invoicetable thead tr th {
  background-color: #0e3786 !important;
  color: #fff;
  font-weight: 900;
}

.custom-select {
  border-radius: 5px !important;
}

/* .noborder {
  border: none !important;
} */

.css-1un219-control:hover {
  border: 1px solid #1d1d1d !important;
  border-radius: 10px !important;
}

.css-1un219-control {
  border: 1px solid #1d1d1d !important;
  border-radius: 10px !important;
}

.css-rhuryn-control {
  border-radius: 10px !important;
  border: 1px solid #1d1d1d !important;
}

.modal-Small {
  max-width: 800px !important;
}

.newalert .success {
  background-color: #28a745;
  color: #fff;
  padding: 10px;
}

.newalert .danger {
  background-color: red;
  color: #fff;
  padding: 10px;
}

.newalert .warning {
  background-color: yellow;
  color: #000;
  padding: 10px;
}

.newalert .info {
  background-color: #0e3786;
  color: #fff;
  padding: 10px;
}

.newalert .right {
  position: absolute;
  z-index: 1111;
  bottom: 10%;
}

.newalert .preicon .fa {
  padding: 10px;
  background-color: #00000036;
  margin-right: 10px;
}

.newalert .posticon .fa {
  padding: 5px;
}

.posticon {
  float: right;
}

.meter {
  height: 20px;
  /* Can be anything */
  position: relative;
  background: #dfdfdf;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  /* padding: 10px; */
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  padding: 1px;
}

.meter > span.green {
  display: block;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: rgb(43, 194, 83);
  background-image: linear-gradient(
    center bottom,
    rgb(43, 194, 83) 37%,
    rgb(84, 240, 84) 69%
  );
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
  padding-left: 0px;
  animation: load 3s normal forwards;
}

.meter > span.red {
  display: block;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: rgb(206, 55, 55);
  background-image: linear-gradient(center bottom, red, black);
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
  padding-left: 0px;
  animation: load 3s normal forwards;
}

@keyframes load {
  0% {
    width: 0;
  }
}

.metervalue {
  font-weight: 900;
  padding-right: 10px;
}

.meter > span.blue {
  display: block;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #0e3786;
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
  padding-left: 0px;
  animation: load 3s normal forwards;
}

.newpiechart {
  width: 400px;
  height: 400px;
  border-radius: 50%;
}

.avatar-preview span {
  font-size: 40px;
  position: absolute;
  z-index: 0;
  top: 33%;
  left: 35%;
  font-weight: 900;
}

.css-1xzjvfp-control {
  margin-bottom: 10px !important;
}

.price {
  padding-left: 23px !important;
}

img {
  position: relative;
}

/* img[alt]:after {  

  content: attr(alt);
  font-size: 40px;
  position: absolute;
  z-index: -1;
  top: 33%;
  left: 35%;
  font-weight: 900;
} */

/* .avatar-preview img[alt]{  

  content: attr(alt);
  font-size: 40px;
  position: absolute;
  z-index: 0;
  font-weight: 900;
}

.avatar-preview img[alt]::before{  

  content: attr(alt);
  font-size: 40px;
  position: absolute;
  z-index: 0;
  font-weight: 900;
  top: 32%;
  left: 34%;
} */

.app main {
  /* background-color: #f1f4f6; */
  background: #ecf3ff;
  padding: 0;
  overflow-x: hidden;
}

.pro-sidebar .pro-menu.active .pro-menu-item > .pro-inner-item:hover {
  color: #0e3786;
}

.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 18px;
}

.pro-menu.active {
  background-color: #ffffff;
  color: #0e3786 !important;
  border-radius: 0 0.75rem 0.75rem 0;
}

.pro-sidebar-content .pro-menu {
  color: #fff;
}

.pro-sidebar .pro-menu.active .pro-menu-item > .pro-inner-item:hover {
  color: #0e3786;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: #d8d8d8;
}

.newnotification::after {
  display: none !important;
}

.xopplecardwrapper {
  background: #fff;
  padding: 15px;
  box-shadow: 0px 0px 10px 0px #ccc;
  border-radius: 5px;
  /* display: grid; */
  position: relative;
  /* height: 130px; */
}

.border-left-success {
  border-left: 0.25rem solid #1cc88a !important;
}

.border-left-primary {
  border-left: 0.25rem solid #4e73df !important;
}

.border-left-info {
  border-left: 0.25rem solid #36b9cc !important;
}

.border-left-warning {
  border-left: 0.25rem solid #f6c23e !important;
}

.border-left-danger {
  border-left: 0.25rem solid #e74a3b !important;
}

.cardtitle {
  padding: 10px 0px;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 1.2rem;
}

.cardcount {
  color: #1d1d1d;
  font-size: 1.1rem;
}

.xopplecardwrapper .icon {
  color: #dbdbdb;
  font-size: 26px;
  line-height: 2.7em;
}

.mainhead-icon {
  font-size: 20px;
  color: #0e3786;
}

.xopplenotify {
  width: 320px;
  max-width: 320px;
  background-color: #f2f5f9 !important;
  padding: 0px !important;
  max-height: 423px;
  overflow-y: scroll;
}

.xopplenotify::-webkit-scrollbar-thumb {
  background-color: #f6c23e;
}

.xopplenotify a {
  color: #0e3786;
  display: block;
  /* height: 60px; */
  /* overflow: hidden; */
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  line-height: 1 !important;
  text-align: justify;
}

.xopplenotify a:hover {
  color: #fff;
}

.newbutton {
  background-color: #1d1d1d !important;
  color: #fff !important;
  border: 2px solid #1d1d1d !important;
}

.newbutton:hover {
  background-color: transparent !important;
  color: #1d1d1d !important;
  border: 2px solid #1d1d1d;
}

.xopplenotify .singlenotification {
  position: relative;
  /* padding: 15px; */
  /* border-bottom: 1px solid #e7e7e73b; */
}

.notify_row {
  margin-right: 0px !important;
  margin-left: -10px !important;
}

/* .xopplenotify p{
  border-bottom: 1px dotted #ababab;
} */

.xopplenotify .notification-header {
  position: sticky;
  top: 0;
  background-color: #0e3786;
  z-index: 1;
  display: block;
  padding: 5px 15px;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 5px;
}

.xopplenotify p:hover {
  color: #e7e7e7;
}

.notify_list {
  background-color: #f1f4f6 !important;
}

.notify_list a {
  background: #fff;
  padding: 10px;
  margin: 7px 10px;
  border-radius: 5px;
  transition: 0.5s all;
  box-shadow: 0px 0px 10px -6px #0e3786;
}

.notify_list a:hover {
  background-color: #0e3786;
  transition: 0.5s;
}

.notify_list p:hover {
  color: #fff !important;
}

.noti_pad {
  padding: 0px !important;
}

.xopplenotify .notifyicon {
  position: absolute;
  padding: 6px;
  background: #0e3786;
  top: 5px;
  color: #e7e7e7;
  width: 28px;
  height: 28px;
  text-align: center;
  border-radius: 0.25rem;
  border-radius: 7px;
}

.xopplenotify a:hover .notifyicon {
  background: #fff;
  color: #0e3786;
}

.notify_title {
  color: #fff;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  #menubars {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}

.xopplenotify .notification-header .count {
  float: right;
  font-size: 12px;
  background: #ff0018;
  /* display: block; */
  padding: 5px 8px;
  border-radius: 12px;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
}

.xopplenotify::-webkit-scrollbar-thumb {
  background-color: #1d1d1d;
}

.xopplenotify a {
  color: #0e3786;
  display: block;
  /* height: 60px; */
  /* overflow: hidden; */
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  line-height: 1 !important;
  text-align: justify;
}

.xopplenotify a:hover {
  color: #e7e7e7;
}

.date {
  text-align: right;
}

.date span {
  /* background: #f6c23e; */
  /* background-color: #0e3786; */
  color: #adadad;
  padding: 2px 10px;
  border-radius: 8px;
  font-size: smaller;
}

.css-1un219-control {
  margin-bottom: 10px !important;
}

.terminalcard-scroll {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.pro-sidebar > .pro-sidebar-inner {
  background-color: #0e3786 !important;
}

.pro-sidebar {
  color: #fff !important;
}

.btn-outline-custom {
  color: #0e3786 !important;
  border-color: #0e3786 !important;
  background-color: transparent !important;
}

.btn-group .active {
  background-color: #0e3786 !important;
  color: #fff !important;
}

.btn-outline-custom:hover {
  background-color: #0e3786 !important;
  color: #fff !important;
}

@media (max-width: 480px) {
  .summary-footer h4 {
    font-size: 0.9rem;
    font-weight: 900;
  }
}

.heading-leftside {
  vertical-align: middle;
  vertical-align: -webkit-baseline-middle;
  display: inline-block;
}

.heading-rightside {
  margin-bottom: 0 !important;
  /* float: right; */
  text-align: end;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .heading-rightside {
    margin-bottom: 0 !important;
    /* float: right; */
    text-align: left;
    padding: 15px 0px;
  }
}

.filterbutton {
  text-align: end;
}

@media (max-width: 480px) {
  .filterbutton {
    text-align: left;
  }
}

/* .checkboxcustom{
  margin-top: 5px;
}

.checkboxcustom2{
  margin-bottom: 5px;
} */

.qrscan-custom {
  width: 500px !important;
  height: 500px !important;
}

@media (max-width: 480px) {
  .qrscan-custom {
    width: 360px !important;
    height: 360px !important;
  }
}

@media (max-width: 375px) {
  .qrscan-custom {
    width: 280px !important;
    height: 280px !important;
  }
}

.filterbutton {
  text-align: end;
}

@media (max-width: 480px) {
  .filterbutton {
    text-align: left;
  }
}

/* 
.loginform input:focus{
  color: #000 !important;
} */

@media (max-width: 480px) {
  .logintitle {
    font-size: 1.6rem;
    text-transform: uppercase;
  }

  .loginsubtitle {
    font-size: 1.1rem;
    text-transform: uppercase;
  }
}

/* .usercard{
  visibility: hidden;
  display: none;
}

@media (max-width: 420px){
  .usercard{
    visibility: visible;
    display: block;
  }
} */

.primarycolumn {
  visibility: visible;
  white-space: nowrap;
}

.secondarycolumn {
  visibility: visible;
  white-space: nowrap;
}

.plussign {
  /* visibility: hidden; */
  display: none !important;
}

.supportLocation {
  padding-left: 0 !important;
}

.datasearch {
  text-align: right;
}

.mobiletr {
  display: none;
  visibility: hidden;
}

.headicon {
  padding: 9px;
  color: #0e3786;
}

.modal-title .headicon {
  color: #fff;
  padding: 9px;
}

@media (max-width: 480px) {
  .secondarycolumn {
    visibility: hidden;
    display: none;
  }

  /* .plussign {
    display: inline-block;
    margin-right: 10px;
    padding: 3px;
    background: #007d08;
    color: #fff;
  } */

  .plussign {
    /* visibility: visible; */
    display: inline-block !important;
    margin-right: 10px;
    padding: 5px;
    background: #0e3786;
    border-radius: 5px;
    color: #fff;
    /* width: 21%; */
    text-align: center;
  }

  .supportLocation {
    padding-left: 12 !important;
  }

  .card-body {
    padding: 10px !important;
  }

  .mobcard {
    padding: 10px !important;
  }

  /* .tableheading {
    display: none;
    visibility: hidden;
  } */

  .datasearch {
    /* width: 274px !important; */
    margin-right: 0px !important;
    /* margin-bottom: 10px !important; */
    padding-top: 15px !important;
    text-align: center;
  }

  .datasearch input[type="search"] {
    width: 100% !important;
  }

  /* .closesearchbar .fa {
    bottom: -20px;
  } */

  .mobiletr {
    display: table-row;
    visibility: visible;
  }

  .toppad {
    padding-top: 15px !important;
  }

  .leftpad {
    padding-left: 15px !important;
  }

  .mobcard {
    padding: 10px !important;
  }

  .mobiletr > td > ul > li:last-child {
    display: flex;
    align-items: center;
  }
}

.statusred {
  background: #e2354936;
  padding: 2px 10px;
  border-radius: 5px;
}

.statusgreen {
  background: #00844a26;
  padding: 2px 10px;
  border-radius: 5px;
}

.statusblue {
  background: #0737ff36;
  padding: 2px 10px;
  border-radius: 5px;
}

.btn-outline-custom {
  color: #0e3786 !important;
  border-color: #0e3786 !important;
}

.btn-outline-custom:hover {
  background-color: #0e3786 !important;
  color: #fff !important;
}

.btn-custom {
  color: #fff !important;
  background-color: #0e3786 !important;
  border-color: #0e3786 !important;
}

.btn-custom:hover {
  color: #0e3786 !important;
  border-color: #0e3786 !important;
  background-color: transparent !important;
  transition: 0.5s;
}

@media (max-width: 768px) {
  .app main {
    padding: 0px;
  }
}

.invoiceh4 {
  font-size: 1.5rem;
  color: #0e3786;
}

.mobtotal {
  padding-left: 50% !important;
}

@media (max-width: 768px) {
  .mobtotal {
    padding-left: 25% !important;
  }

  .invoiceh4 {
    font-size: 1rem;
    color: #0e3786;
  }
}

.stretch-card {
  /* display: -webkit-flex;
  display: flex; */
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-justify-content: stretch;
  justify-content: stretch;
}

.stretch-card .card.card-dark-blue {
  background: #ffffff;
  color: #0e3786;
  border-left: 3px solid #0e3786 !important;
  transition: 1s ease-out;
}

.stretch-card .card.card-dark-blue:hover {
  background: linear-gradient(
    180deg,
    rgba(14 55 134 / 27%) 0%,
    rgba(255, 255, 255, 1) 60%
  );
  color: #0e3786;
}

/* 
.stretch-card .card.card-light-blue {
  background: #7978E9;
  color: #0e3786;
}

.stretch-card .card.card-tale {
  background: #7DA0FA;
  color: #0e3786;
}

.stretch-card .card.card-light-danger {
  background: #F3797E;
  color: #0e3786;
} */

.stretch-card > .card {
  width: 100%;
  min-width: 100%;
}

.stretch-card .summary-footer {
  padding: 0px;
  border-top: none;
  color: #0e3786;
  font-size: 30px;
  text-align: left;
}

.stretch-card .card-headline {
  color: #0e3786;
  font-weight: 600;
  text-transform: uppercase;
}

.stretch-card .thead-icon {
  background: #0e3786;
}

.stretch-card .subtitle {
  font-size: 12px;
  text-overflow: ellipsis;
  display: flex;
  font-weight: 800;
}

.stretch-card .count {
  font-size: 20px;
  padding-top: 4px;
}

@media (max-width: 480px) {
  .stretch-card .card-body {
    padding: 10px !important;
  }

  .mob .navbar-nav {
    flex-direction: row !important;
  }
}

.app main header {
  padding: 0 15px;
}

.nav-bar {
  align-items: center;
  flex-direction: row !important;
}

.hamburger_btn {
  float: left;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .xopplenotify {
    position: absolute !important;
    z-index: 1000 !important;
    left: 0px !important;
  }

  .profiledrop {
    position: absolute !important;
  }

  .hamburger_btn {
    position: relative;
    top: 4px;
    left: 0;
  }
}

@media (max-width: 319px) {
  .hamburger_btn {
    position: relative;
    top: -2px;
    left: 0;
  }
}

/* .app main header {
  margin: 0 -15px;
} */

@media (min-width: 768px) {
  .app main header {
    padding: 0 15px;
    margin: 0;
  }
}

@media (max-width: 575px) {
  .income-chart-title {
    padding-bottom: 16px;
  }
}

/* @media screen and (max-width: 1280px) and (min-width: 1024px){
  .pro-sidebar{
    position: fixed;
    left: -270px;
  }
  .pro-sidebar .md .toggled{
    left: 0px;
  }
  .app .btn-toggle {
    display: flex;
  }

  #menubars{
    display: none;
  }
} */

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6 !important;
  /* border: 1px solid #0e3786 !important; */
  padding: 0.55rem !important;
}

.css-qg19kw-control {
  border-radius: 10px !important;
}

.css-1r5gbi5-menu {
  z-index: 15 !important;
}

.headerDropDown {
  width: 237px;
}

.headerDropDown .dropdown-item:hover {
  color: #0e3786 !important;
  text-decoration: none;
  background-color: #e9ecef !important;
  box-shadow: none !important;
}

.termsAndConditionDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: relative;
  height: 100vh;
  background: linear-gradient(138deg, #2a308f 50%, #fb6c50 50%);
}

.termsAndConditionDiv ul {
  list-style: none;
  margin: 2em;
  padding: 0;
}

.termsAndConditionDiv li {
  margin: 0;
  margin-bottom: 1em;
  padding-left: 1.5em;
  position: relative;
}

.termsAndConditionDiv li::after {
  content: "";
  height: 0.5em;
  width: 0.5em;
  background: #fa4324;
  display: block;
  position: absolute;
  transform: rotate(45deg);
  top: 0.5em;
  left: 0;
}

.pgcard {
  border-radius: 0.75rem 0.75rem 0px 0px !important;
}

.flip-card {
  height: 110px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.9s;
  transform-style: preserve-3d;
  padding: 10px;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card:hover .flip-card-front {
  display: none;
}

.flip-card:hover .flip-card-back {
  display: block;
}

.flip-card-back {
  display: none;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.stat_card_main {
  font-size: 14px;
}

#search_input {
  padding-right: 30px;
}

.btn-primary {
  color: #fff !important;
  background-color: #0e3786 !important;
  border: 2px solid #0e3786 !important;
}

.btn-primary:hover {
  color: #0e3786 !important;
  border: 2px solid #0e3786 !important;
  background-color: transparent !important;
  transition: 0.5s;
  /* font-weight: bold; */
}

.btn-danger {
  color: #fff;
  background-color: #c82333 !important;
  /* background: linear-gradient(-45deg, #dc3545, #bd1c11, #e25e55, #e8162a) !important; */
  border: 2px solid #c82333 !important;
  font-size: 16px !important;
  padding: 7px 13px !important;
}

.btn-danger:hover {
  color: #c82333 !important;
  background-color: transparent !important;
  border: 2px solid #c82333 !important;
  font-size: 16px !important;
  padding: 7px 13px !important;
}

i#profile_add_icon {
  background: #0e3786;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  position: absolute;
  left: 0px;
  cursor: pointer;
  transition: 0.5s all;
}

.exclamation_mark {
  margin-top: 4px;
}

.invoice_top_btn {
  justify-content: flex-end;
}

.invoice_top_btn .invoiceViewPdf {
  padding: 1px 6.5px;
}
.invoice_top_btn .invoiceViewMail,
.invoice_top_btn .invoiceViewEdit {
  padding: 1px 5px;
}
.invoice_top_btn .invoiceViewDelete {
  padding: 1px 6px;
}

.sign_label label {
  padding-left: 0px !important;
}

.maint_modal {
  padding: 5px !important;
}

.date_drop {
  position: relative;
  z-index: 2;
}

.image_file_name {
  word-break: break-all;
}

@media only screen and (max-width: 1400px) {
  .table td,
  .table th {
    font-size: 14px;
  }

  .count_stat,
  .count_dash {
    font-size: 16px !important;
  }

  .exclamation_mark {
    margin-top: 0px;
  }
}

.stat_card_main {
  font-size: 14px;
}

.count_stat {
  font-size: 14px !important;
}

.stat_card_main {
  padding-right: 0 !important;
}

/* .stat_value_acc {
  font-size: 12px !important;
} */

.stat_card_new {
  justify-content: space-between;
  align-items: center;
}

.stat_value {
  color: #0e3786;
  font-weight: 600;
  font-size: 15px;
}

.count_stat,
.count_dash {
  font-size: 18px;
  padding-top: 4px;
}

.acc_card_pad {
  padding: 17.5px !important;
}

.stat_rep_card {
  width: 50%;
  padding: 0px 17px 10px 18px;
}

.stat_rep_card h6 {
  margin-bottom: 0px;
}

@media only screen and (max-width: 1584px) {
  .stat_rep_card {
    width: 100%;
    padding-bottom: 0;
  }

  .count_stat {
    font-size: 13px !important;
  }

  .stat_rep_card h6 {
    /* margin: 8px 0px 11px 0px; */
    margin-bottom: 8px;
  }

  .stat_value {
    font-size: 14px !important;
  }
}

@media (max-width: 385px) {
  .notify_list {
    right: -45px !important;
  }
}

.upload-doc-box {
  border: 1px solid #ccc;
  padding: 3px 6px !important;
  border-radius: 6px;
  margin: 5px;
  overflow: auto;
  overflow-wrap: break-word;
}

@media (max-width: 400px) {
  .acc-add-modal {
    font-size: 15px !important;
  }
}

@media (max-width: 768px) {
  .eye-btn i {
    padding: 0 !important;
  }

  .location-comission {
    border-top: 1px solid #00000020 !important;
  }
}

.location-details {
  padding: 10px !important;
}

.css-1xzjvfp-control,
.css-1un219-control {
  margin-bottom: 0 !important;
}

.react-datepicker__triangle {
  left: 220px !important;
}

.pad-l {
  padding-left: 23px !important;
}

@media (max-width: 1200px) {
  .addPurBtnStat {
    margin-top: 9px;
  }
}

@media (max-width: 480px) {
  .mobiletr > td > ul > li {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.invoiceDetailTxt,
.invoicePrevDate {
  padding: 24px 15px;
}

@media (max-width: 480px) {
  .invoiceDetailTxt {
    padding-bottom: 4px;
  }

  .invoicePrevDate {
    padding-top: 0;
  }
}

.signPadBtn {
  display: flex;
  align-items: center;
  flex-direction: row;
}

/* @media(max-width: 767px) {
  .locationAddPage {
    padding-left: 0 !important;
  }
} */

.btn-white {
  color: #0e3786 !important;
}

.userProfilePic,
.userViewMob-1 {
  border-right: 1px solid #dee2e6 !important;
}

@media (max-width: 991px) {
  .userProfilePic,
  .userViewMob-1 {
    border: none !important;
  }

  .userProfileName {
    text-align: center;
  }

  .userViewMobFirst {
    border-top: 1px solid #dee2e6 !important;
  }
}

.prkTab {
  background-color: #ecf3ff;
  border-radius: 0.75rem !important;
}

.prkTab .nav-link {
  text-align: center;
  color: #0e3786 !important;
  padding: 10px !important;
  cursor: pointer;
  font-weight: 500;
  font-size: inherit !important;
  border: 0px !important;
  text-transform: uppercase;
}

.prkTab .nav-item.active {
  background-color: #0e3786 !important;
  border-radius: 0.75rem !important;
}

.prkTab .nav-item.active > a {
  color: #fff !important;
}

.fullscreen > .react-images__positioner > div:nth-child(3) {
  width: 50%;
}

.inputDollarSign {
  padding-left: 23px !important;
}

.app main header {
  position: sticky;
  top: 0px;
  z-index: 99;
}

.filteredOptions,
.clearFilterBtn {
  /* background-color: #0e3786 !important; */
  /* color: #fff !important; */
  font-size: 11px !important;
  font-weight: bold;
  border-radius: 15px !important;
  display: flex !important;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  text-transform: capitalize;
  padding: 6px 0 6px 12px !important;
  /* margin-right: 10px; */
}

.filterBtn {
  margin-left: 10px !important;
}

.filteredOptions span,
.clearFilterBtn span {
  margin-right: 2px;
}

.app main header {
  position: sticky;
  top: 0px;
  z-index: 100;
}

.filterTimes {
  margin-left: 0.3em;
}

.filterOptionAccount {
  margin-top: 7px;
}

.signature-modal {
  background-color: #f1f4f6;
}

@media (max-width: 575px) and (min-width: 520px) {
  .signature-modal canvas {
    width: 477px;
  }
}

@media (max-width: 575px) {
  .signature-modal canvas {
    max-width: 100%;
  }
}

.menuCompanyName {
  color: #adadad;
  margin: 5px 0;
}

.userMainEmail {
  overflow: auto;
  word-break: break-word;
  white-space: normal;
}

.prk_card_1 {
  width: 40%;
  float: left;
}

.prk_card_2,
.prk_card_3 {
  width: 30%;
  float: left;
  margin-top: 3px;
}

.prk_card_4 {
  width: 30%;
  float: right;
  margin-top: 6px;
}

@media only screen and (max-width: 1400px) {
  .prk_card_1,
  .prk_card_2,
  .prk_card_3,
  .prk_card_4 {
    width: 100%;
    float: left;
  }

  .prk_card_2 {
    text-align: right;
  }

  .prk_cash_card_main_1,
  .prk_cash_card_main_2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
}

@media only screen and (max-width: 1112px) {
  .prk_card_1 {
    width: 100%;
    float: left;
  }

  .prk_card_4,
  .prk_card_2 {
    width: 50%;
    float: left;
  }

  .prk_cash_card_main_1,
  .prk_cash_card_main_2 {
    display: block;
  }
}

@media (max-width: 991px) {
  .prk_card_1,
  .prk_card_1 {
    width: 50%;
    float: left;
  }

  .prk_card_4 {
    width: 100%;
    float: left;
    margin-top: 0;
  }
}

@media (max-width: 786px) {
  .prk_card_1 {
    width: 100%;
    float: left;
  }

  .prk_card_4,
  .prk_card_2 {
    width: 50%;
    float: left;
  }

  .prk_card_4 {
    margin-top: 3px;
  }
}

@media (max-width: 768px) {
  .prk_card_1,
  .prk_card_1 {
    width: 50%;
    float: left;
  }

  .prk_card_4 {
    width: 100%;
    float: left;
    margin-top: 0;
  }
}

@media (max-width: 486px) {
  .prk_card_1 {
    width: 100%;
    float: left;
  }

  .prk_card_4,
  .prk_card_2 {
    width: 50%;
    float: left;
    margin-top: 0;
  }

  .prk_card_4 {
    margin-top: 3px;
  }
}

@media (max-width: 357px) {
  .prk_card_1,
  .prk_card_2,
  .prk_card_4 {
    width: 100%;
    float: left;
  }
}

.prkCashReportCard {
  width: 100%;
  float: left;
}

/* Error and Success toast notification or alert */

.error-toast,
.success-toast {
  opacity: 1 !important;
  width: 500px;
  max-width: none !important;
  flex-basis: auto;
  top: 1%;
}

.error-toast {
  background-color: #f00 !important;
  border: 1px solid #f00 !important;
}

.success-toast {
  background-color: #00ff3a !important;
  border: 1px solid #00ff3a !important;
}

.error-toast-header,
.success-toast-header {
  position: relative;
  border: none;
  height: auto;
  overflow: hidden !important;
  border-bottom: none !important;
}

.error-toastIcon,
.success-toastIcon {
  position: absolute;
  font-size: 7rem !important;
  left: -9%;
}

.error-toastIcon {
  color: #ff000054 !important;
}

.success-toastIcon {
  color: #00b72a54 !important;
}

.error-toast-body,
.success-toast-body {
  position: relative;
  z-index: 1;
  margin-left: 50px;
}

.error-toast-body strong,
.success-toast-body strong {
  font-size: 16px;
}

.error-toast-body strong {
  color: #f00 !important;
}

.success-toast-body strong {
  color: #00c12b !important;
}

.error-toast-body p,
.success-toast-body p {
  font-size: 16px;
  word-break: break-word;
}

.error-toast-body p {
  color: #f00 !important;
}

.success-toast-body p {
  color: #00bb2a !important;
}

.error-toast-header .close {
  color: #f00 !important;
}

.success-toast-header .close {
  color: #009522 !important;
}

@media (max-width: 575px) {
  .error-toast,
  .success-toast {
    max-width: 300px !important;
  }
  .error-toastIcon,
  .success-toastIcon {
    left: -15%;
  }
}

/* Error and Success toast notification or alert close */

.pro-sidebar .pro-menu a,
.pro-sidebar .pro-menu a:hover {
  color: #007bff !important;
}

/* New datepicker start */

.datePickerMian {
  overflow-x: auto;
  position: absolute;
  z-index: 11;
  max-width: 100%;
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 3px #000;
}

.datePickerMian .newDatePicker {
  flex-direction: column !important;
  max-width: 100% !important;
}

.datePickerMian .newDatePicker .rdrDefinedRangesWrapper {
  width: auto !important;
}

.datePickerMian .newDatePicker .rdrMonths .rdrMonth {
  max-width: 100% !important;
  width: auto !important;
}

.datePickerMian .newDatePicker .rdrDefinedRangesWrapper .rdrStaticRanges {
  flex-direction: row !important;
  overflow: auto;
  padding: 10px;
}

.datePickerMian .newDatePicker .rdrDefinedRangesWrapper .rdrInputRanges {
  display: none;
  flex-direction: row !important;
  padding: 0 !important;
}

.datePickerMian
  .newDatePicker
  .rdrDefinedRangesWrapper
  .rdrStaticRanges
  .rdrStaticRange {
  border: 1px solid #f0f0f0 !important;
  margin: 0px 4px 4px 4px !important;
}

.datePickerMian
  .newDatePicker
  .rdrDefinedRangesWrapper
  .rdrStaticRanges
  .rdrStaticRange
  .rdrStaticRangeLabel {
  text-align: center;
  padding: 5px 10px !important;
  color: #0e3786 !important;
  white-space: nowrap;
}

@media (max-width: 501px) {
  .datePickerMian .newDatePicker .rdrDefinedRangesWrapper,
  .newDatePicker {
    max-width: 100% !important;
  }
  .datePickerMian .newDatePicker .rdrMonths .rdrMonth {
    max-width: 100% !important;
  }
}

@media (max-width: 705px) {
  .datePickerMian .newDatePicker {
    max-width: 100% !important;
  }
}

.datepickerBtn {
  margin: 0 10px 10px 0;
  float: right;
}

/* New datepicker close */

.filter-popover {
  position: relative;
}

.cursor-pointer {
  cursor: pointer !important;
}

.locationAddPage {
  position: relative;
  z-index: 99 !important;
}

.locationAddPage i {
  padding: 8px 14px;
}

.reportCardTableMain .table-responsive {
  max-height: 555px;
}

.inputtabs-btn-mail {
  max-height: 250px;
  overflow: auto;
}

.inputtabs-btn-mail li button {
  background-color: #0a3583;
  color: #fff !important;
  border-radius: 25px !important;
  border: 2px solid #0a3583;
}

.inputtabs-btn-mail li button:hover {
  background-color: #fff;
  border: 2px solid #0a3583;
  color: #0a3583 !important;
  border-radius: 25px !important;
}

.inputtabs-btn-mail li {
  display: inline-block;
  margin: 2px;
}

/* report module tab section start */

.reportTabMain {
  box-shadow: -3px 3px 3px -3px;
}

.reportTabMain .card-body {
  background: #fff;
  border-radius: 0 0.25rem 0.25rem 0.25rem;
  box-shadow: 0px 0px 10px -3px #212529;
}

.reportTab .nav-link {
  background-color: #0e3786;
  border-radius: 0.25rem 0.25rem 0px 0px;
  color: #fff;
  font-size: 14px;
  border-bottom: none;
}

.reportTab .nav-item.show .nav-link,
.reportTab .nav-link.active {
  color: #0e3786;
  border-radius: 0.25rem 0.25rem 0px 0px;
  background: #fff;
  box-shadow: 3px -2px 3px -3px #212529, -3px -3px 3px -3px #212529;
  font-weight: bold;
}

@media (max-width: 600px) {
  .reportTab .nav-item {
    width: 100%;
    padding: 0 !important;
    border: 1px solid #fff;
  }

  .reportTab .nav-item:nth-child(2) p,
  .reportTab .nav-item:nth-child(3) p {
    border-radius: 0 !important;
  }

  .reportTab .nav-item.show .nav-link,
  .reportTab .nav-link.active {
    box-shadow: none;
    border: 2px solid #0e3786;
  }
}

/* report module tab section end */

.excelBtn {
  background: none;
  border: none;
}

.excel-icon {
  font-size: 20px !important;
  padding: 6px 12px 6px 10px !important;
  margin-top: 1px;
}

.pdf-icon {
  font-size: 20px !important;
  padding: 6px 10px 6px 10px !important;
}

.reportCardMain {
  max-height: 93vh;
}

.fullscreen > .react-images__positioner > div:nth-child(2) {
  width: 50%;
}

.upload-doc-box a {
  color: #007bff !important;
}

.iconLoaderDiv {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #e7eefa78;
  z-index: 8888;
}

@keyframes iconSpinAnimate {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(-45deg);
  }
}

.iconSpin {
  animation: iconSpinAnimate 1s infinite;
  display: table-cell;
  vertical-align: middle;
}

.newUserRegister {
  color: #007bff !important;
}

/* TOGGLE SWITCH CSS START */

.t-knobs,
.t-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.t-button {
  position: relative;
  width: 100px;
  height: 36px;
}

.t-button.t-b2,
.t-layer {
  border-radius: 2px;
}

.t-checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.t-knobs {
  z-index: 2;
}

.t-layer {
  width: 100%;
  background-color: #dbe7ff;
  z-index: 1;
  transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
  -moz-transition: 0.3s ease all;
  -ms-transition: 0.3s ease all;
  -o-transition: 0.3s ease all;
}

#t-button .t-knobs:before,
#t-button .t-knobs span {
  content: "SHOW";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 45px;
  height: 28px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
}

#t-button .t-knobs:before {
  transition: 0.3s ease all, left 0.5s cubic-bezier(0.18, 0.89, 0.35, 1.15);
  z-index: 2;
}

#t-button .t-knobs span {
  background-color: #0e3786;
  border-radius: 2px;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
  z-index: 1;
}

#t-button .t-checkbox:checked + .t-knobs:before {
  content: "HIDE";
  left: 51px;
}

#t-button .t-checkbox:checked + .t-knobs span {
  left: 51px;
  background-color: #c82333;
}

#t-button .t-checkbox:checked ~ .t-layer {
  background-color: #ffd1d5;
}

.t-span {
  position: absolute;
  top: 10px;
  z-index: 1;
  font-size: 11px;
  color: #777;
}
.t-span-1 {
  left: 10px;
}
.t-span-2 {
  right: 14px;
}

/* TOGGLE SWITCH CSS END */

.accNewBtn {
  background-color: #0e3786;
  color: #fff !important;
  border: 2px solid #0e3786;
  width: 200px !important;
  border-radius: 20px;
}
.accNewBtn a {
  color: #fff;
}

.accNewBtn:hover {
  border: 2px solid #0e3786;
  background-color: #fff;
}
.accNewBtn:hover a {
  color: #0e3786 !important;
}

.accNewBtn span {
  padding-left: 10px;
  padding-top: 5px;
}

.captchaImg {
  background: linear-gradient(
      45deg,
      rgba(221, 221, 238, 0.5) 12%,
      transparent 0,
      transparent 88%,
      rgba(221, 221, 238, 0.5) 0
    ),
    linear-gradient(
      135deg,
      transparent 37%,
      rgba(170, 170, 187, 0.5) 0,
      rgba(170, 170, 187, 0.5) 63%,
      transparent 0
    ),
    linear-gradient(
      45deg,
      transparent 37%,
      rgba(221, 221, 238, 0.5) 0,
      rgba(221, 221, 238, 0.5) 63%,
      transparent 0
    ),
    #c4c4c4;
  background-size: 40px 40px;
  width: 50%;
  height: 100%;
  position: relative;
  user-select: none;
}

.captchaImg h2 {
  color: #464646;
  font-family: "Fredericka the Great", cursive;
}

.captchaImg button {
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 100%;
  border: none;
  bottom: 5px;
  right: 8px;
  transition: 0.5s all;
}

.captchaImg button:hover {
  background: rgba(255, 255, 255, 1);
}

.captchaImg button:focus {
  outline: none;
}

.spanTagPad {
  padding: 5.5px 12px !important;
}

.backBtn {
  padding: 8px 12px !important;
}

.manageExpCat {
  padding: 8px 10px;
}

.managePurCat {
  padding: 8px 15.5px;
}

.lineHeight1 {
  line-height: 1;
}

.accBalIcon,
.accExpIcon {
  padding: 2.5px 8.5px;
}

.accReceiveIcon,
.accSettIcon,
.accPurIcon {
  padding: 6px 7.5px;
}

.accDepoIcon {
  padding: 6px 6.5px;
}

.accCollIcon {
  padding: 6px 12px;
}

.margin-r-5 {
  margin-right: 5px;
}

.margin-r-10 {
  margin-right: 10px;
}

.accHeadTitle {
  padding: 6px 14.4px;
}

.accSelectedInvoiceList {
  max-height: 300px;
}

.inputAmountDollar {
  position: absolute;
  z-index: 1;
  color: #495057;
  padding: 2px 10px;
  line-height: 35px;
}

.billListIcon {
  padding: 8px 14px;
}

.inventBrandIcon,
.inventListIcon {
  padding: 8px 12px;
}

.inventViewCardIcons,
.locationViewCollCardsIcon {
  padding: 6px 8.5px;
}

.inventViewDetailsIcon {
  padding: 4px 5px;
}

.inventHistIcon,
.inventModListIcon {
  padding: 8px 12px;
}

.inventUpdHistIcon {
  padding: 8px 14px;
}

.inventViewTable {
  max-height: 410px;
}

.flex-1 {
  flex: 1;
}

.bg-blue-2 {
  background: #2863d2;
}

.invoiceUploadDoc {
  min-width: 200px;
}

.signatureBtn {
  font-size: 1rem;
  margin-right: 10px;
  vertical-align: top;
}

.signatureImg {
  height: 80px;
  width: 100px;
}

.invoiceTableMail {
  border: 0px;
  border-radius: 20px;
  padding: 5px 10px;
}

.invoiceTablePrint {
  border: 0px;
  border-radius: 20px;
  padding: 5px 10px;
  background: rgb(29, 29, 29);
}

.invoiceListIcon,
.locationHeadIcon {
  padding: 8px 14px;
}

.font-13 {
  font-size: 13px;
}

.invoiceSummary {
  background: #f1f4f6;
}

.anchorLinkColor {
  color: #007bff;
}

.invoiceViewTable {
  overflow-x: auto;
}

.invoiceViewInvoiceNo {
  border-top: 1px dotted #fff;
}

.invoiceViewUserDetails {
  background: #0e3786;
  color: #fff;
  padding: 0px 15px;
}

.invoiceViewActionBtns {
  font-size: 0.85rem;
}

.emailModal {
  max-height: 700px;
}

.searchInput {
  padding-right: 30px;
}

.InventViewListIcon {
  padding: 4px 11px;
}

.inventViewDocIcon {
  font-size: 0.85rem !important;
  padding: 1px 5px !important;
}

.locationProfileIcon {
  font-size: 18px;
  padding: 7px 11px;
}

.locationProfileCards {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.maintenanceListIcon {
  padding: 4.5px 12px;
}

.prkReportIcon {
  padding: 8px 12px;
}

/* .prkCashReportCard {
  background: #ecf3ff;
} */

.prkListIcon {
  padding: 8px 10px;
}

.runReportBtn {
  margin: 15px 0 0;
}

.reportBantStatBtn {
  padding: 2px 10px;
  font-size: 20px;
}

.userListIcon {
  padding: 8px 13px;
}

.userViewMain {
  background: #f1f4f6;
  color: #0e3786;
}

.sidebar-btn-wrapper {
  padding: 20px 24px;
}

/* .sidebarImage {
  padding: 0px 2px 0px 0px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
} */

.themed-container {
  margin-top: 50px;
}

.dashChart {
  min-height: 350px;
  max-height: 350px;
}

.loginLogo {
  padding: 0px 20px 20px 20px;
}

.loginLogo img {
  max-width: 100%;
}

.loginText {
  padding-left: 20px;
  border-left: 10px solid #2863d2;
  margin-bottom: 30px;
}

.topHeader {
  background: rgb(255, 255, 255);
  margin-left: -15px;
  margin-right: -15px;
  padding: 12px;
  box-shadow: 0px 0px 10px 1px;
}

.font-20 {
  font-size: 20px !important;
}

.mainDropdownBtn {
  padding: 2px 10px 2px 2px;
}

.noNofification {
  color: #0e3786;
}

.list-style-none {
  list-style: none;
}

.registerPage,
.resetPasswordPage {
  padding-left: 20px;
  border-left: 10px solid #2863d2;
  margin-bottom: 30px;
}

.t-c-logo {
  width: 250px;
  height: 80px;
}

.unsubscribePage {
  font-size: 7rem;
  color: #2a308f;
}

.fixedTopTh {
  position: relative;
  z-index: 1;
}

.notification-box {
  position: absolute;
  z-index: 99;
  width: 24px;
  height: 24px;
  text-align: center;
}
.notification-bell {
  animation: bell 1s 1s both infinite;
}
.notification-bell * {
  display: block;
  margin: 0 auto;
  background-color: var(--white);
  box-shadow: 0px 0px 15px var(--white);
}
.bell-rad {
  width: 8px;
  height: 4px;
  margin-top: 2px;
  border-radius: 0 0 4px 4px;
  animation: rad 1s 2s both infinite;
}
.notification-count {
  position: absolute;
  z-index: 1;
  top: -12px;
  width: 24px;
  height: 24px;
  line-height: 26px;
  font-size: 18px;
  border-radius: 50%;
  background-color: #ff4927;
  color: var(--white);
  font-size: 14px;
  animation: zoom 3s 3s both infinite;
}
.notification-sidebar {
  position: fixed;
  width: fit-content;
  /* max-width: 40%; */
  padding: 1rem 1.5rem 2.5rem;
  right: 0;
  z-index: 9999;
  background-color: #fff;
  height: 100%;
  top: 0px;
  transition: width, left, right, 0.3s;
  display: flex;
  flex-direction: column;
}
.receipt-qr {
  max-width: 18%;
}
.receipt-image {
  max-width: 40%;
}
.sidebar-image {
  overflow: auto;
}
.sidebar-image img {
  height: auto;
  max-height: 100vh;
  max-width: 100%;
}
.side-notification-header {
  padding: 0.5rem 0 1.5rem;
}
.notification-sidebar-inactive {
  position: fixed;
  max-width: 40%;
  padding: 1.5rem;
  right: -40%;
  z-index: 9999;
  background-color: #fff;
  height: 100%;
  top: 0px;
  transition: width, left, right, 0.3s;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}
.notification-sidebar h2 {
  color: white;
  text-align: center;
}
.notification-sidebar.active {
  transition-timing-function: ease-in-out;
  right: 0px;
  overflow: auto;
}
.notibox {
  color: #a7a7a7;
  background-color: #0d0d0d;
  padding: 15px;
  margin: 14px;
  border-radius: 4px;
  position: relative;
  letter-spacing: 0.2px;
  font-weight: 200;
  text-align: left;
  font-size: 14px;
}
.notibox span {
  font-size: 16px;
  padding: 0;
  color: var(--dark-text);
  letter-spacing: 0.4px;
  font-weight: 700;
  margin: 4px !important;
}
.cancel {
  position: absolute;
  right: 7px;
  top: 10px;
  cursor: pointer;
  padding: 3px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 20px;
}
.cancel:hover {
  color: black;
  background-color: white;
}
.gone {
  display: none;
}
.notifiation-header {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  padding: 0 0 0.5rem;
  border-bottom: 2px dashed rgba(28, 28, 28, 0.7);
}
.notifiation-header img {
  max-width: 100%;
}
.notification-sidebar h3 {
  font-size: 16px;
  text-align: center;
  padding: 0.7rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0;
}
.notification-sidebar .store {
  font-size: 16px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #353535;
  padding: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0;
}

.sidebar_img_span:focus {
  outline: none !important;
}
.sidebar_img_span {
  cursor: pointer;
}
.sidebar-logo {
  display: flex;
  justify-content: space-between;
}
.sidebar-logo i {
  cursor: pointer;
  margin-right: 12px;
}

/*! CSS Used from: Embedded */
* {
  box-sizing: border-box;
}
.main-details {
  /* width: 250px; */
  width: 100%;
  float: left;
  overflow-y: auto;
  padding: 0 12px;
  text-align: center;
}
.date-details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 0 1rem 0;
}
.date-details .item {
  display: flex;
  flex-direction: column;
  min-width: 70px;
}
.date-details .item span {
  font-size: 0.9rem;
  color: #353535;
  font-weight: 700;
  letter-spacing: 0.5px;
}
.date-details .item h3 {
  margin-top: 10px;
  margin-bottom: 25px;
  font-size: 12px;
  padding: 6px 0;
  text-align: left;
}
.details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 12px;
}
.details .item {
  display: flex;
  flex-direction: column;
  min-width: 70px;
}
.details .item span {
  font-size: 0.8em;
  color: rgba(28, 28, 28, 0.7);
  font-weight: 700;
  padding-bottom: 6px;
  letter-spacing: 0.5px;
}
.details .item h3 {
  font-size: 12px;
  padding: 8px 0;
  text-align: left;
}
.license-level {
  font-size: 0.9rem;
  border-top: 2px dashed rgba(28, 28, 28, 0.7);
  color: rgba(28, 28, 28, 0.7);
  font-weight: 700;
  text-align: center;
  padding: 0.7rem;
  letter-spacing: 0.5px;
}

.terminal-level {
  font-size: 0.9rem;
  border-top: none;
  cursor: default;
  color: #000;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: 0;
}
.prominent-games {
  font-size: 1rem;
  color: #353535;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.5px;
}

.v-align-m tr td {
  vertical-align: top !important;
}
.spanTagPad {
  padding: 5.5px 12px !important;
}

.thead-icon-dollar {
  /* padding: 0px 10px; */
  margin-left: 6px;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  color: #0e3786;
}

.note {
  display: flex;
  align-items: center;
  /* margin-top: 12px; */
}
.note h6 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 18px;
  margin-right: 4px;
}

.note span {
  margin-right: 10px;
  font-size: 14px;
}
/* .note span:first-child {
  margin-left: 2px;
 } */

.note span img {
  margin-right: 6px;
}
.note-block {
  display: flex;
  margin-top: 12px;
}
.note-block h6 {
  margin-bottom: 0;
  margin-right: 8px;
}
@media only screen and (max-width: 1401px) and (min-width: 1201px) {
  .receipt-qr {
    max-width: 25%;
  }
  .receipt-image {
    max-width: 50%;
  }
  .notification-sidebar-inactive {
    position: fixed;
    max-width: 40%;
    padding: 1.5rem;
    right: -50%;
    z-index: 9999;
    background-color: #fff;
    height: 100%;
    top: 0px;
    transition: width, left, right, 0.3s;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
  }
}

@media only screen and (max-width: 1200px) and (min-width: 768px) {
  .receipt-qr {
    max-width: 30%;
  }
  .receipt-image {
    max-width: 60%;
  }
  .notification-sidebar-inactive {
    position: fixed;
    max-width: 40%;
    padding: 1.5rem;
    right: -60%;
    z-index: 9999;
    background-color: #fff;
    height: 100%;
    top: 0px;
    transition: width, left, right, 0.3s;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
  }
}
@media only screen and (max-width: 768px) and (min-width: 580px) {
  .receipt-qr {
    max-width: 40%;
  }
  .receipt-image {
    max-width: 80%;
  }
  .license-level {
    font-size: 12px;
  }

  .notification-sidebar-inactive {
    position: fixed;
    max-width: 40%;
    padding: 1.5rem;
    right: -80%;
    z-index: 9999;
    background-color: #fff;
    height: 100%;
    top: 0px;
    transition: width, left, right, 0.3s;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
  }

  .note {
    display: flex;
    /* margin-top: 12px; */
    align-items: center;
  }
  .note span {
    margin-left: 0px;
  }
}

@media (max-width: 579px) {
  .receipt-qr {
    max-width: 75%;
  }
  .receipt-image {
    max-width: 90%;
  }
  .notification-sidebar-inactive {
    position: fixed;
    max-width: 40%;
    padding: 1.5rem;
    right: -75%;
    z-index: 9999;
    background-color: #fff;
    height: 100%;
    top: 0px;
    transition: width, left, right, 0.3s;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
  }

  .license-level {
    font-size: 12px;
  }
  .note {
    display: flex;
    /* margin-top: 12px; */
    flex-direction: column;
    /* justify-content: left; */
    align-items: start;
  }
  .note span {
    margin-left: 0px;
  }
  .sidebar-logo {
    display: flex;
    flex-direction: column;
  }
  .terminal-level {
    margin-top: 12px;
  }
}

.prkCashSetRportCrads {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.prkCashRepCardsWAtm {
  width: 311px;
  padding: 10px;
}
.prkCashRepCardsWOAtm {
  width: 389px;
  padding: 10px;
}

.prkTDataHead {
  border-bottom: 1px dotted #0e3786;
  margin-bottom: 10px;
}

.prkTDataTID {
  font-size: 18px;
  letter-spacing: 0.5px;
}

.mailContainer {
  min-height: 100vh;
}

.imageRotateControls {
  position: fixed;
  bottom: 0;
  margin: 16px 0;
}

.invoiceViewBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.invoiceViewBox .inner-box {
  font-size: 12px;
  border: 1px solid #d7d7d7;
  padding: 3px 4px;
  margin-right: 6px;
  margin-bottom: 6px;
  background: #e7e7e7;
}

.imageRotateControls button {
  padding: 1px 6px !important;
}

.form-control:disabled,
.form-control[readonly] {
  background: #d7d7d7 !important;
}
